import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// import Detail from './pages/Detail';

import Nav from './components/Nav';
// import { StoreProvider } from './utils/GlobalState';



const Scanner = lazy(() => import('./pages/Scanner'))

const Home = lazy(() => import('./pages/Home'));
const Detail = React.lazy(() => import('./pages/Detail'))
const ArtistDetail = lazy(() => import('./pages/ArtistDetail'));
const ConfirmTickets = lazy(() => import('./pages/ConfirmTickets'));
const Payment = lazy(() => import('./components/Payment'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Admin = lazy(() => import('./pagesAdmin/Admin'));
const OrganizerAdmin = lazy(() => import('./pagesAdmin/OrganizerAdmin'));
const OrganizerLogin = lazy(() => import('./pagesAdmin/OrganizerLogin'));
const OrganizerEvent = lazy(() => import('./pagesAdmin/OrganizerEvent'));
const Login = lazy(() => import('./pagesAdmin/Login'));
const Success = lazy(() => import('./pages/Success'));
const OrderHistory = lazy(() => import('./pages/OrderHistory'));
const Searches = lazy(() => import('./pages/Search'));
const Terms = lazy(() => import('./pages/Terms&Conditions'));
const Policies = lazy(() => import('./pages/Policies'));
const Contact = lazy(() => import('./pages/Contact'));

// Spanish Pages
const TermsES = lazy(() => import('./pagesES/Terms&Conditions'));
const PoliciesES = lazy(() => import('./pagesES/Policies'));

const Vote = lazy(() => import('./votingPages/vote'));


const TicketsPDFGenerator = lazy(() => import('./test/pdfGen'));

const AudioPlayer = lazy(() => import('./musicPlayer/AudioPlayer'))
const AudioPlayer2 = lazy(() => import('./musicPlayer2/AudioPlayer2'))
const AudioPlayer3 = lazy(() => import('./musicPlayer3/AudioPlayer2'))
const AudioPlayer4 = lazy(() => import('./musicPlayer4/AudioPlayer2'))
const AudioPlayer5 = lazy(() => import('./musicPlayer5/AudioPlayer2'))
const AudioPlayer6 = lazy(() => import('./musicPlayer6/AudioPlayer2'))
const AudioPlayer7 = lazy(() => import('./musicPlayer7/AudioPlayer2'))


const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className='container-main'>
          {/* <StoreProvider> */}
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route 
                  path="/" 
                  element={<Home />} 
                />
                <Route 
                  path="/search/:keyword" 
                  element={<Searches />} 
                />
                <Route 
                  path="/admin/login" 
                  element={<Login />} 
                />
                <Route 
                  path="/organizer/login" 
                  element={<OrganizerLogin />} 
                />
                <Route 
                  path="/success" 
                  element={<Success />} 
                />
                <Route 
                  path="/terms" 
                  element={<Terms />} 
                />
                <Route 
                  path="/policies" 
                  element={<Policies />} 
                />
                <Route 
                  path="/terms/es" 
                  element={<TermsES />} 
                />
                <Route 
                  path="/policies/es" 
                  element={<PoliciesES />} 
                />
                <Route 
                  path="/contact" 
                  element={<Contact />} 
                />
                <Route 
                  path="/orderHistory" 
                  element={<OrderHistory />} 
                />
                <Route 
                  path="/products/:id" 
                  element={<Detail />} 
                />
                <Route 
                  path="/artists/:id" 
                  element={<ArtistDetail />} 
                />
                <Route 
                  path="/events/:id" 
                  element={<Detail />} 
                />
                <Route 
                  path="/confirm-tickets/:id" 
                  element={<ConfirmTickets />} 
                />
                <Route path="/payment"
                element={<Payment />} 
                />
                <Route 
                  path="/admin" 
                  element={<Admin />} 
                />
                <Route 
                  path="/organizer" 
                  element={<OrganizerAdmin />} 
                />
                <Route 
                  path="/organizer/:id" 
                  element={<OrganizerEvent />} 
                />
                <Route 
                  path="organizer/:id/scan" 
                  element={<Scanner />} 
                />
                {/* <Route 
                  path="amazingawards/vote/:categoryId" 
                  element={<Vote />} 
                /> */}
                <Route 
                  path="/:eventId/vote/:categoryId" 
                  element={<Vote />} 
                />
                <Route 
                  path="/audio" 
                  element={<AudioPlayer />} 
                />
                <Route 
                  path="/audio2" 
                  element={<AudioPlayer2 />} 
                />
                                <Route 
                  path="/audio3" 
                  element={<AudioPlayer3 />} 
                />
                                <Route 
                  path="/audio4" 
                  element={<AudioPlayer4 />} 
                />
                                <Route 
                  path="/audio5" 
                  element={<AudioPlayer5 />} 
                />
                                <Route 
                  path="/audio6" 
                  element={<AudioPlayer6 />} 
                />
                <Route 
                  path="/audio7" 
                  element={<AudioPlayer7 />} 
                />
                <Route 
                  path="*" 
                  element={<NoMatch />} 
                />
              </Routes>
            </Suspense>
          {/* </StoreProvider> */}
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
