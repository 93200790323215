import React from "react";
import Auth from "../../utils/auth";
import { Link } from "react-router-dom";
import casadelosticketslogo from '../../assets/casadelosticketslogo.jpg'
import casadelosticketslogoNoBg from '../../assets/casadelosticketslogo-remove-bg.png'

function Nav() {

  return (
    <header className="header-nav">
      <nav className="navbar">
        <div className="logo-container"><a href="/"><img src={casadelosticketslogoNoBg} alt="logo"/></a></div>
        <input type="checkbox" id="toggler"/>
        <label htmlFor="toggler"><i className="fa-sharp fa-solid fa-bars"></i></label>
        <div className="menu">
            <ul className="list">
                <li>
                    <a href="/" className="link">Home</a>
                </li>
                <li class="dropdown">
                  <button href="/" class="dropbtn link">About <i class="fa-solid fa-caret-down"></i></button>
                  <div class="dropdown-content">
                    <a href="/" className="link">About Us</a>
                    <a href="/contact" className="link">Contact Us</a>
                    <a href="/terms" className="link">Terms & Conditions</a>
                    <a href="/policies" className="link">Privacy Policy</a>
                  </div>
                </li>
                <li>
                    <a href="/#artists" className="link">Artists</a>
                </li>
                <li>
                    <a href="https://amazingawardsdjw.com/" className="link">Events</a>
                </li>
            </ul>
        </div>

      </nav>
    </header>
  );
}

export default Nav;
